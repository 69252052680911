import React from "react";
import { useNavigate } from "react-router-dom";

const PageHeader = ({ title, showBtn=true }) => {
  const navigate = useNavigate();

  return (
    <section className="form-menu py-sm-4 py-3">
      <div className="container">
        <div className="menu-wrap">
          <h4>{title}</h4>
          {
            showBtn &&
            <button onClick={() => navigate("/")}>Back</button>
          }
        </div>
      </div>
    </section>
  );
};

export default PageHeader;
