import React from 'react'
import MetaData from '../helpers/MetaData'
import PageHeader from '../components/PageHeader'
import {Link} from 'react-router-dom'

const Home = () => {
  return (
    <>
        <MetaData title="Home - ISKCON of Bhiwandi" />
        <PageHeader title="All Forms" showBtn={false} />
        <section className='all-forms pb-5 pt-2'>
            <div className="container">
                <div className="form-name-wrap">
                <div className="row m-0 w-100">
                    <div className="col-lg-6 col-md-8 mx-auto">
                            <div className="form-name mb-4">
                                <Link to="/donation-form">Donation Form</Link>
                            </div>
                            <div className="form-name mb-4">
                                <Link to="/sales-form">Sales Form</Link>
                            </div>
                            <div className="form-name">
                                <Link to="/bhagavad-gita-form">Bhagavad Gita Class Form</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Home