import React, { useEffect, useState } from "react";
import MetaData from "../helpers/MetaData";
import notification from "../helpers/notification";
import PageHeader from "../components/PageHeader";
import { useForm } from "react-hook-form";
import ApiService from "../API/ApiService";
import { useNavigate } from "react-router-dom";

const BhagavadGitaForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [showTempleInput, setShowTempleInput] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [templeLocations, setTempleLocations] = useState([]);
  const [classTypes, setClassTypes] = useState([]);
  const [preferredLocations, setPreferredLocations] = useState([]);
  const [occupations, setOccupations] = useState([]);

  const templeWatch = watch("templeLocation");
  const navigate = useNavigate()

  // Campaign fetch
  const fetchOptions = async () => {
    try {
      const { classTypes, campaigns, templeLocations, preferredLocations, occupations } = await ApiService.fetchOptions();
      setCampaigns(campaigns);
      setTempleLocations(templeLocations);
      setClassTypes(classTypes);
      setPreferredLocations(preferredLocations)
      setOccupations(occupations)
    } catch (error) {
      console.log(error);
    }
  };

  const handleForm = async(values) => {
    try {
      setLoading(true);

      const { message } = await ApiService.SubmitBhagavadGitaForm(values);
      notification("success", message);

      reset();
      setLoading(false);
      navigate('/')

    } catch (error) {
      setLoading(false);
      notification("error", (error && error?.data) ? error?.data?.message : 'Unable to submit form');
    }
  };

  // Other temple location input toggle
  useEffect(() => {
    if (templeWatch === "Others") {
      setShowTempleInput(true);
    } else {
      setShowTempleInput(false);
    }
  }, [templeWatch]);

  useEffect(() => {
    fetchOptions();
  }, []);

  return (
    <>
      <MetaData title="Bhagavad Gita Form - ISKCON of Bhiwandi" />
      <PageHeader title="Bhagavad Gita Class Form" />

      <section className="join-family-sec pt-0">
        <div className="container">
          <div className="join-family-form">
            <form onSubmit={handleSubmit(handleForm)}>
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="donorName" className="required-field">
                      Donor Name
                    </label>
                    <input
                      type="text"
                      placeholder="Donor Name"
                      {...register("donorName", {
                        required: true,
                      })}
                    ></input>
                    {errors.donorName?.type === "required" && (
                      <small className="text-danger">
                        Donor Name is required{" "}
                      </small>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="mobile" className="required-field">
                      Mobile Number
                    </label>
                    <input
                      type="tel"
                      placeholder="Mobile"
                      maxLength={10}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      {...register("mobile", {
                        required: true,
                        pattern: {
                          value: /^[6-9]\d*$/,
                          message: "Please provide valid phone number",
                        },
                        minLength: {
                          value: 10,
                          message: "Phone number must be minimum 10 digit",
                        },
                      })}
                    />
                    {errors.mobile?.type === "required" && (
                      <small className="text-danger error">
                        Mobile is required
                      </small>
                    )}
                    {errors.mobile && (
                      <small className="text-danger error">
                        {errors.mobile.message}
                      </small>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="campaign" className="required-field">
                      Campaign
                    </label>
                    <select
                      {...register("campaign", {
                        required: true,
                      })}
                    >
                      <option value="">--- Select Campaign ---</option>
                      {campaigns?.map((campaign) => (
                        <option value={campaign?._id} key={campaign?._id}>
                          {campaign?.name}
                        </option>
                      ))}
                    </select>
                    {errors.campaign?.type === "required" && (
                      <small className="text-danger error">
                        Campaign is required
                      </small>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="templeLocation" className="required-field">
                      Temple Location
                    </label>
                    <select
                      {...register("templeLocation", {
                        required: true,
                      })}
                    >
                      <option value="">--- Select Temple Location ---</option>
                      {templeLocations?.map((templeLocation) => (
                        <option
                          value={templeLocation?._id}
                          key={templeLocation?._id}
                        >
                          {templeLocation?.name}
                        </option>
                      ))}
                      <option value="Others">Others</option>
                    </select>
                    {errors.templeLocation?.type === "required" && (
                      <small className="text-danger error">
                        Temple Location is required
                      </small>
                    )}
                  </div>
                </div>

                {showTempleInput && (
                  <div className="col-lg-4 col-md-6">
                    <div className="form-part">
                      <label htmlFor="otherTempleLocation">
                        Temple Location
                      </label>
                      <input
                        type="text"
                        placeholder="Temple Location"
                        {...register("otherTempleLocation", {
                          required: true,
                        })}
                      ></input>
                      {errors.otherTempleLocation?.type === "required" && (
                        <small className="text-danger error">
                          Temple Location is required
                        </small>
                      )}
                    </div>
                  </div>
                )}

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="classType" className="required-field">
                      Class Type
                    </label>
                    <select
                      {...register("classType", {
                        required: true,
                      })}
                    >
                      <option value="">--- Select Class Type ---</option>
                      {classTypes?.map((classType) => (
                        <option value={classType?._id} key={classType?._id}>
                          {classType?.name}
                        </option>
                      ))}
                    </select>
                    {errors.classType?.type === "required" && (
                      <small className="text-danger error">
                        Class Type is required
                      </small>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="preferredLocation" className="required-field">
                      Preferred Location
                    </label>
                     <select
                      {...register("preferredLocation", {
                        required: true,
                      })}
                    >
                      <option value="">--- Select Preferred Location ---</option>
                      {preferredLocations?.map((location) => (
                        <option value={location?._id} key={location?._id}>
                          {location?.name}
                        </option>
                      ))}
                    </select>
                    {errors.preferredLocation?.type === "required" && (
                      <small className="text-danger error">
                        Preferred location is required
                      </small>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label
                      htmlFor="preferredLanguage"
                      className="required-field"
                    >
                      Preferred Language
                    </label>
                    <input
                      type="text"
                      placeholder="Preferred language"
                      {...register("preferredLanguage", {
                        required: true,
                      })}
                    ></input>
                    {errors.preferredLanguage?.type === "required" && (
                      <small className="text-danger">
                        Preferred language is required{" "}
                      </small>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="maritalStatus" className="required-field">
                      Marital Status
                    </label>
                    <select
                      {...register("maritalStatus", {
                        required: true,
                      })}
                    >
                      <option value="">--- Select Marital Status ---</option>
                      <option value="married">Married</option>
                      <option value="unmarried">Unmarried</option>
                    </select>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="gender" className="required-field">
                      Gender
                    </label>
                    <select
                      {...register("gender", {
                        required: true,
                      })}
                    >
                      <option value="">--- Select Gender ---</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="occupation" className="required-field">
                      Occupation
                    </label>
                    <select
                      {...register("occupation", {
                        required: true,
                      })}
                    >
                      <option value="">--- Select Occupation ---</option>
                      {occupations?.map((occupation) => (
                        <option value={occupation?._id} key={occupation?._id}>
                          {occupation?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className={showTempleInput ? "col-lg-4" : "col-lg-8"}>
                  <div className="form-part">
                    <label htmlFor="remark">Remark</label>
                    <input
                      type="text"
                      placeholder="Remark"
                      {...register("remark")}
                    ></input>
                  </div>
                </div>

                <div className="col-12">
                  <div className="d-flex justify-content-center">
                    <button
                      type="submit"
                      className="box-hover custom-btn-cls donation_btn ms-0 donate-now-clicked-form"
                      disabled={loading ? true : false}
                    >
                      {loading ? (
                        <div className="d-flex align-items-center">
                          <div
                            className="spinner-border text-light"
                            role="status"
                          ></div>
                          <div className="ms-2">DONATING...</div>
                        </div>
                      ) : (
                        <>SUBMIT</>
                      )}
                    </button>
                  </div>
                </div>

              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default BhagavadGitaForm;
