import React, { useEffect, useState } from "react";
import MetaData from "../helpers/MetaData";
import notification from "../helpers/notification";
import PageHeader from "../components/PageHeader";
import { useForm } from "react-hook-form";
import ApiService from "../API/ApiService";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SalesForm = () => {

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm();

  
  const [loading, setLoading] = useState(false);
  const [showTempleInput, setShowTempleInput] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [templeLocations, setTempleLocations] = useState([]);
  const [items, setItems] = useState([]);
  const [departments, setDepartments] = useState([]);

  const templeWatch = watch("templeLocation");
  const navigate = useNavigate()

    // Campaign fetch
    const fetchOptions = async () => {
      try {
        const {  items, departments, campaigns, templeLocations } = await ApiService.fetchOptions();
        setCampaigns(campaigns);
        setTempleLocations(templeLocations);
        setItems(items);
        setDepartments(departments);
      } catch (error) {
        console.log(error);
      }
    };

      // Fetch city and state by pin
  const fetchStateCity = async (pin) => {
    try {
      let url = `https://api.postalpincode.in/pincode/${pin}`;

      let config = {
        method: "GET",
        url,
      };
      const { data } = await axios(config);
      if(!data || data[0].Status === 'Error' || data[0].Status === '404') {
        return notification("warning", "Enter valid PIN code!");
      };

      let cityVal = '';
      if (data[0]?.PostOffice?.length > 1) {
        data[0].PostOffice.map(ct => {
          if (ct.Block !== 'NA') {
            return cityVal = ct.Block
          }else {
            return false;
          }
        })
      } else {
        cityVal = data[0]?.PostOffice[0]?.Block;
      }

      if (data[0].Status === "Success") {
        setValue("city", cityVal !== '' ? cityVal : data[0].PostOffice[0].District);
        setValue("state", data[0].PostOffice[0].State);
      }
    } catch (err) {
      console.log(err);
    }
  };


  const handleForm = async(values) => {
    try {
      
      setLoading(true);

      const { message } = await ApiService.SubmitSaleForm(values);
      notification("success", message);

      reset();
      setLoading(false);
      navigate('/')

    } catch (error) {
      setLoading(false);
      notification("error", (error && error?.data) ? error?.data?.message : 'Unable to submit form');
    }
  }

    // Other temple location input toggle
    useEffect(() => {
      if (templeWatch === "Others") {
        setShowTempleInput(true);
      } else {
        setShowTempleInput(false);
      }
    }, [templeWatch]);
  
    useEffect(() => {
      fetchOptions();
    }, []);

  return (
    <>
      <MetaData title="Sales Form - ISKCON of Bhiwandi" />
      <PageHeader title="Sales Class Form" />

      <section className="join-family-sec pt-0">
        <div className="container">
          <div className="join-family-form">
          <form onSubmit={handleSubmit(handleForm)}>
            <div className="row">
              
            <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="donorName" className="required-field">
                      Donor Name
                    </label>
                    <input
                      type="text"
                      placeholder="Donor Name"
                      {...register("donorName", {
                        required: true,
                      })}
                    ></input>
                    {errors.donorName?.type === "required" && (
                      <small className="text-danger">
                        Donor Name is required{" "}
                      </small>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="mobile" className="required-field">
                      Mobile Number
                    </label>
                    <input
                      type="tel"
                      placeholder="Mobile"
                      maxLength={10}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      {...register("mobile", {
                        required: true,
                        pattern: {
                          value: /^[6-9]\d*$/,
                          message: "Please provide valid phone number",
                        },
                        minLength: {
                          value: 10,
                          message: "Phone number must be minimum 10 digit",
                        },
                      })}
                    />
                    {errors.mobile?.type === "required" && (
                      <small className="text-danger error">
                        Mobile is required
                      </small>
                    )}
                    {errors.mobile && (
                      <small className="text-danger error">
                        {errors.mobile.message}
                      </small>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="campaign" className="required-field">
                      Campaign
                    </label>
                    <select
                      {...register("campaign", {
                        required: true,
                      })}
                    >
                      <option value="">--- Select Campaign ---</option>
                      {campaigns?.map((campaign) => (
                        <option value={campaign?._id} key={campaign?._id}>
                          {campaign?.name}
                        </option>
                      ))}
                    </select>
                    {errors.campaign?.type === "required" && (
                      <small className="text-danger error">
                        Campaign is required
                      </small>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="templeLocation" className="required-field">
                      Temple Location
                    </label>
                    <select
                      {...register("templeLocation", {
                        required: true,
                      })}
                    >
                      <option value="">--- Select Temple Location ---</option>
                      {templeLocations?.map((templeLocation) => (
                        <option
                          value={templeLocation?._id}
                          key={templeLocation?._id}
                        >
                          {templeLocation?.name}
                        </option>
                      ))}
                      <option value="Others">Others</option>
                    </select>
                    {errors.templeLocation?.type === "required" && (
                      <small className="text-danger error">
                        Temple Location is required
                      </small>
                    )}
                  </div>
                </div>

                {showTempleInput && (
                  <div className="col-lg-4 col-md-6">
                    <div className="form-part">
                      <label htmlFor="otherTempleLocation">
                        Temple Location
                      </label>
                      <input
                        type="text"
                        placeholder="Temple Location"
                        {...register("otherTempleLocation", {
                          required: true,
                        })}
                      ></input>
                      {errors.otherTempleLocation?.type === "required" && (
                        <small className="text-danger error">
                          Temple Location is required
                        </small>
                      )}
                    </div>
                  </div>
                )}

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="item" className="required-field">
                      Item
                    </label>
                    <select
                      {...register("item", {
                        required: true,
                      })}
                    >
                      <option value="">--- Select Item ---</option>
                      {items?.map((item) => (
                        <option value={item?._id} key={item?._id}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                    {errors.item?.type === "required" && (
                      <small className="text-danger error">
                        Item is required
                      </small>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="department" className="required-field">
                    Department
                    </label>
                    <select
                      {...register("department", {
                        required: true,
                      })}
                    >
                      <option value="">--- Select Item ---</option>
                      {departments?.map((department) => (
                        <option value={department?._id} key={department?._id}>
                          {department?.name}
                        </option>
                      ))}
                    </select>
                    {errors.department?.type === "required" && (
                      <small className="text-danger error">
                        Department is required
                      </small>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                      <label htmlFor="requestType" className="required-field">
                      Request Type
                      </label>
                      <select 
                      {...register("requestType", {
                        required: true,
                      })}
                    >
                      <option value="">--- Select Request Type ---</option>
                      <option value="free-gift">Free Gift</option>
                      <option value="purchase">Purchase</option>
                    </select>
                    {errors.requestType?.type === "required" && (
                      <small className="text-danger">
                        Request type is required
                      </small>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                      <label htmlFor="quantity" className="required-field">
                        Quantity
                      </label>
                      <input
                      type="text"
                      placeholder="Quantity"
                      {...register("quantity")}
                    ></input>
                        {errors.quantity?.type === "required" && (
                      <small className="text-danger">
                        quantity is required
                      </small>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="status" className="required-field">Status</label>
                    <select 
                      {...register("status", {
                        required: true,
                      })}
                    >
                      <option value="">--- Select Status ---</option>
                      <option value="pending">Pending</option>
                      <option value="sales-done">Sales Done</option>
                      <option value="free-gift-send">Free Gift Sent</option>
                      <option value="cancel">Cancel</option>
                      <option value="in-progress">In Progress</option>
                    </select>
                    {errors.status?.type === "required" && (
                      <small className="text-danger">
                        Status is required
                      </small>
                    )}
                  </div>
                </div>

              <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="pincode">Pin Code</label>
                    <input
                      type="text"
                      placeholder="Pin code"
                      {...register("pincode", {
                        pattern: /^-?\d+(\.\d+)?$/,
                        onChange: e => {
                          setValue("state", "");
                          setValue("city", "");
                          if (e.target.value.length === 6) {
                            fetchStateCity(e.target.value);
                          }
                        }
                      })}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      maxLength={6}
                      minLength={6}
                    ></input>
                    {errors?.pincode?.type === "pattern" && (
                      <small className="text-danger error">
                        Please provide valid Pin code
                      </small>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="state">State</label>
                    <input
                      type="text"
                      placeholder="State"
                      {...register("state")}
                    ></input>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="city">City</label>
                    <input
                      type="text"
                      placeholder="City"
                      {...register("city")}
                    ></input>
                  </div>
                </div>

                <div className={showTempleInput ? "col-md-8" :"col-md-6"}>
                  <div className="form-part">
                    <label htmlFor="address">Address</label>
                    <input
                      type="text"
                      placeholder="Address"
                      {...register("address")}
                    ></input>
                  </div>
                </div>

                <div className={showTempleInput ? "col-12" : "col-md-6"}>
                  <div className="form-part">
                    <label htmlFor="remark">Remark</label>
                    <input
                      type="text"
                      placeholder="Remark"
                      {...register("remark")}
                    ></input>
                  </div>
                </div>

                <div className="col-12">
                  <div className="d-flex justify-content-center">
                    <button
                      type="submit"
                      className="box-hover custom-btn-cls donation_btn ms-0 donate-now-clicked-form"
                      disabled={loading ? true : false}
                    >
                      {loading ? (
                        <div className="d-flex align-items-center">
                          <div
                            className="spinner-border text-light"
                            role="status"
                          ></div>
                          <div className="ms-2">DONATING...</div>
                        </div>
                      ) : (
                        <>SUBMIT</>
                      )}
                    </button>
                  </div>
                </div>

            </div>
          </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default SalesForm;
